import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutMePage = () => (
  <Layout>
    <SEO title="About me" />
    <h1 className="title">About me</h1>
    <hr />
    <section className="section">
      <div className="container">
        <h1 className="title">1. Software Engineering</h1>
        <h2 className="subtitle">Can't stop spending time stuck on my computer</h2>
        <p>
          I didn't choose it, I was chosen! I've been into computers since 10, and it's probably not going to stop any
          time soon.
        </p>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h1 className="title">2. Entrepreneurship</h1>
        <h2 className="subtitle">Always push past the comfort zone</h2>
        <p>
          It's sometimes well too easy to go work and enjoy the holidays like many do. If they're happy, that's what
          matters. However, when you want more, it's a constant battle!
        </p>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h1 className="title">3. Helping others</h1>
        <h2 className="subtitle">In different ways</h2>
        <p>
          I used to give private lessons as a teenager, then I trained people to become developer. It's always been
          clear that I have a gift for simplifying crazy concepts into smaller bites that anyone can understand.
        </p>
      </div>
    </section>
  </Layout>
);

export default AboutMePage;
